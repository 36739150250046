import styled from "@emotion/styled"
import Container from "@mui/material/Container"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  padding-top: ${({ theme }) => theme.typography.pxToRem(17)};
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-bottom: 10rem;
  }
`

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 1.5rem;
    flex-direction: row;
  }
`

export const Content = styled.div`
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #64748b;
  }

  h2,
  h3 {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #191d23;
    margin-bottom: 0;

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-top: 1.5rem;
      margin-bottom: 0.25rem;
      font-size: 2rem;
      line-height: ${({ theme }) => theme.typography.pxToRem(38)};
    }
  }

  li {
    color: #64748b;
  }

  img {
    width: 100%;
    height: 100%;
  }
`
